<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

        <b-card no-body class="mb-0">

            <b-row>
                <b-col cols="12">
                    <b-card>

                        <b-card-title class="mb-1">
                            <b-row>
                                <b-col md="8">
                                    <span @click="$router.go(-1)">
                                        <img :src="require('@/assets/images/back.png')" style="width:30px;  margin-right:20px" align="left"/>
                                    </span>
                                    <h4 class="card-title float-left"> 
                                        {{ $route.params.device_name | capitalize }}
                                    </h4>                        
                                </b-col>
                                <b-col md="4">
                                    <PrintPdfTemp :device_type="$route.params.device_type" :device_name="$route.params.device_name" :color="$route.params.color" :temperatureOptions="temperatureChart.chartOptions" :temperatureSeries="temperatureChart.series" :humidityOptions="humidityChart.chartOptions" :humiditySeries="humidityChart.series" :date_range="date_range" v-if="$route.params.device_type == 'Temperature & Humidity Sensor'" />             
                                    <PrintPdf :device_type="$route.params.device_type" :device_name="$route.params.device_name" :color="$route.params.color" :chartOptions="lineAreaChartSpline.chartOptions" :series="lineAreaChartSpline.series" :date_range="date_range" :buttonStatus="lineAreaChartSpline.series && lineAreaChartSpline.series[0] ? lineAreaChartSpline.series[0].data.length : 0" v-else />             
                                </b-col>
                            </b-row>
                            
                            <b-row>
                                <b-col cols="12">
                                    <div class="d-flex align-items-center">
                                        <feather-icon icon="CalendarIcon" size="32" />
                                        <flat-pickr
                                            v-model="date_range"
                                            :config="date_config"
                                            class="form-control flat-picker bg-transparent border-0 shadow-none"
                                            placeholder="DD MMM YYYY"
                                            style="width:200%"
                                            @on-change="getStats()"
                                        />
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-title>

                        <div v-if="$route.params.device_type == 'Temperature & Humidity Sensor'">
                            <b-card-body title="Temperature">
                                <vue-apex-charts
                                    type="area"
                                    height="450"
                                    ref="temperature"
                                    :options="temperatureChart.chartOptions"
                                    :series="temperatureChart.series"
                                />
                            </b-card-body>
                            <b-card-body title="Humidity">
                                <vue-apex-charts
                                    type="area"
                                    height="450"
                                    ref="humidity"
                                    :options="humidityChart.chartOptions"
                                    :series="humidityChart.series"
                                />
                            </b-card-body>
                        </div>
                        <b-card-body v-else>
                            <vue-apex-charts
                                type="area"
                                height="450"
                                ref="srjcchart"
                                :options="lineAreaChartSpline.chartOptions"
                                :series="lineAreaChartSpline.series"
                            />
                        </b-card-body>

                    </b-card>
                </b-col>

            </b-row>

        </b-card>

    </div>
</template>

<script>
    import {
        BCardHeader, BCardTitle, BCardText, BCardBody, BFormSelect, BFormSelectOption, BCard, BRow, 
        BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, 
        BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm, BBreadcrumb
    } from 'bootstrap-vue'
    import { POST_API } from "../../../store/actions.type"
    import vSelect from 'vue-select';
    import VueApexCharts from 'vue-apexcharts'
    import flatPickr from 'vue-flatpickr-component'
    import moment from "moment-timezone";
    import { $themeColors } from '@themeConfig'
    import ChartjsComponentBarChart from '../charts-components/ChartjsComponentBarChart.vue'
    import PrintPdf from './PrintPdf';
    import PrintPdfTemp from './PrintPdfTemp';

    export default {
        components: {
            PrintPdfTemp, PrintPdf, ChartjsComponentBarChart, flatPickr, VueApexCharts, BCard, BCardHeader, BCardTitle, BCardText, 
            BCardBody, vSelect, BFormSelect, BFormSelectOption, BRow, BCol, BFormInput, BButton, BTable, 
            BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, 
            BAlert, BFormText, BFormGroup, BForm, BBreadcrumb
        },

        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 
                showDismissibleAlert:false,

                date_config:{ 
                    mode: 'range',
                    dateFormat: 'd M Y',
                    defaultDate: [
                        moment.utc().subtract(1,'days').format('DD MMM YYYY'),
                        moment.utc().format('DD MMM YYYY')
                    ]
                },

                date_range:moment.utc().subtract(1,'days').format('DD MMM YYYY') +' to '+moment.utc().format('DD MMM YYYY'),

                buildings: [],
                building: '',
                levels: [],
                level: '',

                lineAreaChartSpline: { },
                temperatureChart: { },
                humidityChart: { },
            }
        },

        methods : {
            getStats(){
                var api = null;

                if(this.$route.params.device_type == 'Ammonia Sensor'){
                    api = '/api/ammonia-sensor-stats';
                }
                if(this.$route.params.device_type == 'Traffic Counter Sensor'){
                    api = '/api/pir-sensor-stats';
                }
                if(this.$route.params.device_type == 'Wetness Floor Sensor'){
                    api = '/api/wet-sensor-stats';
                }
                if(this.$route.params.device_type == 'Temperature & Humidity Sensor'){
                    api = '/api/temperature-humidity-sensor-stats';
                }
                if(this.$route.params.device_type == 'Cubicle Occupancy Detection'){
                    api = '/api/cubicle-occupancy-detection-sensor-stats';
                }
                var date = this.date_range.split(' to ');

                return this.$store.dispatch(POST_API, {

                    data:{
                        device_id: this.$route.params.device_id,
                        device_name: this.$route.params.device_name,
                        building: this.building,
                        level: this.level,
                        range: [moment(new Date(date[0])).format('YYYY-MM-DD'), moment(new Date(date[1] ? date[1] : date[0])).format('YYYY-MM-DD')]
                    },
                    api: api
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        var data = this.$store.getters.getResults.data;
                        if(this.$route.params.device_type == 'Temperature & Humidity Sensor'){
                            this.temperatureChart.series = data.temperatureSeries;
                            this.humidityChart.series = data.humiditySeries;

                            this.$refs.temperature.updateSeries(this.temperatureChart.series, true);
                            this.$refs.humidity.updateSeries(this.humidityChart.series, true);
                        } else {
                            this.lineAreaChartSpline.series = data.series;
    
                            // this.lineAreaChartSpline.chartOptions.xaxis.categories = data.categories;
                            if(this.$store.getters.getResults.type == 'ammonia'){
                                this.lineAreaChartSpline.chartOptions.yaxis.max = 20;
                            }
    
                            this.$refs.srjcchart.updateSeries(this.lineAreaChartSpline.series, true);
                        }

                        return this.lineAreaChartSpline;
                    }
                });
            },
            breadCrumb(){
                var item = [{
                    to:{name:'client-dashboard'},
                    text: 'Dashboard',
                },{
                    to:null,
                    text: 'Smart Sensors',
                },{
                    to:{name:'smart-toilet'},
                    text:'Devices'
                },{
                    to:null,
                    text:'Statistics'
                }];
                return item;
            }
        },

        mounted(){
            this.getStats();

            
        },

        created(){
            if(this.$route.params.device_type == "Ammonia Sensor"){
                this.lineAreaChartSpline = { 
                    "series": [], 
                    "chartOptions": {
                        colors: ['#'+this.$route.params.color],
                        "xaxis": {
                            type: 'datetime',
                        }, 
                        "yaxis": {
                            min: 0.1,
                            max: 20,
                            // labels: {
                            //     formatter: function(val) {
                            //         return val.toFixed(3);
                            //         return parseInt(val);
                            //     }
                            // }
                        }, 
                        "dataLabels": { 
                            "enabled": false 
                        }, 
                        "stroke": { 
                            "show": false, 
                            "curve": "straight" 
                        }, 
                        "legend": { 
                            "show": true, 
                            "position": "top", 
                            "horizontalAlign": "left", 
                            "fontSize": "14px", 
                            "fontFamily": "Montserrat" 
                        }, 
                        "grid": { 
                            "xaxis": { 
                                "lines": { 
                                    "show": true 
                                } 
                            } 
                        }, 
                        "fill": { 
                            opacity: 1, 
                            type: "gradient",
                            gradient: {
                                shadeIntensity: 1,
                                inverseColors: false,
                                opacityFrom: 0.5,
                                opacityTo: 0,
                                stops: [0, 90, 100]
                            },
                        }, 
                        dataLabels: {
                            enabled: false,
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                colors: ['#fff'],
                            },
                        },
                        stroke: {
                            curve: 'smooth'
                        },
                        tooltip: { 
                            enabled : true,
                            x: {
                                show: true,
                                format: 'hh:mm - dd MMM, yyyy'
                            },
                            y: {
                                title: {
                                    formatter: (seriesName) => seriesName+' - '
                                }
                            },
                            marker: {
                                show: true
                            }
                        }, 
                        chart: {
                            type: 'area',
                            toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: this.$route.params.device_name,
                                    columnDelimiter: ',',
                                    headerCategory: 'Timestamp',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return moment(timestamp).format('YYYY-MM-DD HH:mm')/* new Date(timestamp).toDateString() */
                                    }
                                },
                                svg: {
                                    filename: this.$route.params.device_name,
                                },
                                png: {
                                    filename: this.$route.params.device_name,
                                }
                            },
                            autoSelected: 'zoom' 
                        },
                        },

                    }
                }
            } else {
                this.lineAreaChartSpline = { 
                    "series": [], 
                    "chartOptions": {
                        colors: ['#'+this.$route.params.color],
                        "xaxis": {
                            type: 'datetime',
                        }, 
                        "yaxis": {
                            labels: {
                                formatter: function(val) {
                                    return parseInt(val);
                                }
                            }
                        }, 
                        "dataLabels": { 
                            "enabled": false 
                        }, 
                        "stroke": { 
                            "show": false, 
                            "curve": "straight" 
                        }, 
                        "legend": { 
                            "show": true, 
                            "position": "top", 
                            "horizontalAlign": "left", 
                            "fontSize": "14px", 
                            "fontFamily": "Montserrat" 
                        }, 
                        "grid": { 
                            "xaxis": { 
                                "lines": { 
                                    "show": true 
                                } 
                            } 
                        }, 
                        "fill": { 
                            opacity: 1, 
                            type: "gradient",
                            gradient: {
                                shadeIntensity: 1,
                                inverseColors: false,
                                opacityFrom: 0.5,
                                opacityTo: 0,
                                stops: [0, 90, 100]
                            },
                        }, 
                        dataLabels: {
                            enabled: false,
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                colors: ['#fff'],
                            },
                        },
                        stroke: {
                            curve: 'smooth'
                        },
                        tooltip: { 
                            enabled : true,
                            x: {
                                show: true,
                                format: 'hh:mm - dd MMM, yyyy'
                            },
                            y: {
                                title: {
                                    formatter: (seriesName) => seriesName+' - '
                                }
                            },
                            marker: {
                                show: true
                            }
                        }, 
                        chart: {
                            type: 'area',
                            toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: this.$route.params.device_name,
                                    columnDelimiter: ',',
                                    headerCategory: 'Timestamp',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return moment(timestamp).format('YYYY-MM-DD HH:mm')/* new Date(timestamp).toDateString() */
                                    }
                                },
                                svg: {
                                    filename: this.$route.params.device_name,
                                },
                                png: {
                                    filename: this.$route.params.device_name,
                                }
                            },
                            autoSelected: 'zoom' 
                        },
                        },

                    }
                };
                
                this.temperatureChart = { 
                    "series": [], 
                    "chartOptions": {
                        colors: ['#'+this.$route.params.color],
                        "xaxis": {
                            type: 'datetime',
                        }, 
                        "yaxis": {
                            labels: {
                                // formatter: function(val) {
                                //     return parseInt(val);
                                // }
                            }
                        }, 
                        "dataLabels": { 
                            "enabled": false 
                        }, 
                        "stroke": { 
                            "show": false, 
                            "curve": "straight" 
                        }, 
                        "legend": { 
                            "show": true, 
                            "position": "top", 
                            "horizontalAlign": "left", 
                            "fontSize": "14px", 
                            "fontFamily": "Montserrat" 
                        }, 
                        "grid": { 
                            "xaxis": { 
                                "lines": { 
                                    "show": true 
                                } 
                            } 
                        }, 
                        "fill": { 
                            opacity: 1, 
                            type: "gradient",
                            gradient: {
                                shadeIntensity: 1,
                                inverseColors: false,
                                opacityFrom: 0.5,
                                opacityTo: 0,
                                stops: [0, 90, 100]
                            },
                        }, 
                        dataLabels: {
                            enabled: false,
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                colors: ['#fff'],
                            },
                        },
                        stroke: {
                            curve: 'smooth'
                        },
                        tooltip: { 
                            enabled : true,
                            x: {
                                show: true,
                                format: 'hh:mm - dd MMM, yyyy'
                            },
                            y: {
                                title: {
                                    formatter: (seriesName) => seriesName+' - '
                                }
                            },
                            marker: {
                                show: true
                            }
                        }, 
                        chart: {
                            type: 'area',
                            toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: this.$route.params.device_name,
                                    columnDelimiter: ',',
                                    headerCategory: 'Timestamp',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return moment(timestamp).format('YYYY-MM-DD HH:mm')/* new Date(timestamp).toDateString() */
                                    }
                                },
                                svg: {
                                    filename: this.$route.params.device_name,
                                },
                                png: {
                                    filename: this.$route.params.device_name,
                                }
                            },
                            autoSelected: 'zoom' 
                        },
                        },

                    }
                }

                this.humidityChart = { 
                    "series": [], 
                    "chartOptions": {
                        colors: ['#'+this.$route.params.color],
                        "xaxis": {
                            type: 'datetime',
                        }, 
                        "yaxis": {
                            // labels: {
                                // formatter: function(val) {
                                //     return parseInt(val);
                                // }
                            // },
                            // decimalsInFloat: 0,
                        }, 
                        "dataLabels": { 
                            "enabled": false 
                        }, 
                        "stroke": { 
                            "show": false, 
                            "curve": "straight" 
                        }, 
                        "legend": { 
                            "show": true, 
                            "position": "top", 
                            "horizontalAlign": "left", 
                            "fontSize": "14px", 
                            "fontFamily": "Montserrat" 
                        }, 
                        "grid": { 
                            "xaxis": { 
                                "lines": { 
                                    "show": true 
                                } 
                            } 
                        }, 
                        "fill": { 
                            opacity: 1, 
                            type: "gradient",
                            gradient: {
                                shadeIntensity: 1,
                                inverseColors: false,
                                opacityFrom: 0.5,
                                opacityTo: 0,
                                stops: [0, 90, 100]
                            },
                        }, 
                        dataLabels: {
                            enabled: false,
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                colors: ['#fff'],
                            },
                        },
                        stroke: {
                            curve: 'smooth'
                        },
                        tooltip: { 
                            enabled : true,
                            x: {
                                show: true,
                                format: 'hh:mm - dd MMM, yyyy'
                            },
                            y: {
                                title: {
                                    formatter: (seriesName) => seriesName+' - '
                                }
                            },
                            marker: {
                                show: true
                            }
                        }, 
                        chart: {
                            type: 'area',
                            toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                download: true,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                reset: true | '<img src="/static/icons/reset.png" width="20">',
                                customIcons: []
                            },
                            export: {
                                csv: {
                                    filename: this.$route.params.device_name,
                                    columnDelimiter: ',',
                                    headerCategory: 'Timestamp',
                                    headerValue: 'value',
                                    dateFormatter(timestamp) {
                                        return moment(timestamp).format('YYYY-MM-DD HH:mm')/* new Date(timestamp).toDateString() */
                                    }
                                },
                                svg: {
                                    filename: this.$route.params.device_name,
                                },
                                png: {
                                    filename: this.$route.params.device_name,
                                }
                            },
                            autoSelected: 'zoom' 
                        },
                        },

                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>