<template>
    <div>
        <!-- control button -->
            <b-button variant="warning" class="ml-1 float-right" @click="generatePDF()" :disabled="temperatureSeries[0].data.length > 0 || humiditySeries[0].data.length > 0 ? false : true">
                <span class="text-nowrap">Export PDF</span>
            </b-button>

        <!-- Print PDF block -->
            <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1398"
                :filename="device_name"
                :pdf-quality="1.2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="695px"
                @progress="onProgress($event)"
                @beforeDownload="beforeDownload($event)"
                ref="html2Pdf"
            >
                <section slot="pdf-content">
                    <section class="pdf-item">
                        <!-- {{ temperatureSeries[0].data.length }} ----
                        {{ humiditySeries[0].data.length }} ---- -->
                        <div class="image-block"></div>                   
                        <b-row v-if="organization_logo || site_logo">
                            <b-col cols="6">
                                <img v-if="site_logo" :src="site_logo" alt="Logo" width="100" height="100" loading="eager"/>
                            </b-col>
                            <b-col cols="6">
                                <img v-if="organization_logo" :src="organization_logo" alt="Logo" width="100" height="100" class="float-right" loading="eager"/>
                            </b-col>
                        </b-row>

                        <div class="text-center" style="width: 730px; font-family: Montserrat">
                            <h4 :style="{'color': '#'+color }" style="font-weight: bold">{{ device_name }}</h4>
                            <h5 :style="{color: '#'+color }">{{ date_range }}</h5>
                        </div>
                    </section>
                    
                    <section class="pdf-item">
                        <h5 :style="{'color': '#'+color }">Temperature</h5>
                        <vue-apex-charts
                            type="area"
                            height="250px"
                            width="700px"
                            ref="temperature"
                            :options="temperatureOptions"
                            :series="temperatureSeries"
                        />
                    </section>

                    <div class="html2pdf__page-break"/>

                    <section class="pdf-item">
                        <table class="table text-center" style="page-break-inside: avoid !important;">
                            <tr>
                                <th width="50%" scope="col">Timestamp</th>
                                <th width="50%" scope="col">Temperature</th>
                            </tr>
                        </table>
                    </section>
                    
                    <section class="pdf-item" v-for="(ss, ind) in this.temperatureSeries[0].data" :key="ind + '_temp'">
                        <table class="table text-center mt-1" style="page-break-inside: avoid !important;">
                            <tr style="page-break-inside: avoid !important;">
                                <td width="50%" v-if="ind % 2 == 0" style="background-color: #fff !important;">
                                    {{ss[0] | unixDateTime}}
                                </td>
                                <td width="50%" v-else style="background-color: #f7f7f7 !important;">
                                    {{ss[0] | unixDateTime}}
                                </td>

                                <td width="50%" v-if="ind % 2 == 0" style="background-color: #fff !important;">
                                    {{ss[1]}}
                                </td>
                                <td width="50%" v-else style="background-color: #f7f7f7 !important;">
                                    {{ss[1]}}
                                </td>
                            </tr>
                        </table>
                    </section>

                    <div class="html2pdf__page-break"/>

                    <section class="pdf-item">
                        <h5 :style="{'color': '#'+color }">Humidity</h5>
                        <vue-apex-charts
                            type="area"
                            height="250px"
                            width="700px"
                            ref="humidity"
                            :options="humidityOptions"
                            :series="humiditySeries"
                        />
                    </section>

                    <div class="html2pdf__page-break"/>

                    <section class="pdf-item">
                        <table class="table text-center" style="page-break-inside: avoid !important;">
                            <tr>
                                <th width="50%" scope="col">Timestamp</th>
                                <th width="50%" scope="col">Humidity</th>
                            </tr>
                        </table>
                    </section>
                    
                    <section class="pdf-item" v-for="(ss, ind) in this.humiditySeries[0].data" :key="ind + '_humidity'">
                        <table class="table text-center mt-1" style="page-break-inside: avoid !important;">
                            <tr style="page-break-inside: avoid !important;">
                                <td width="50%" v-if="ind % 2 == 0" style="background-color: #fff !important;">
                                    {{ss[0] | unixDateTime}}
                                </td>
                                <td width="50%" v-else style="background-color: #f7f7f7 !important;">
                                    {{ss[0] | unixDateTime}}
                                </td>

                                <td width="50%" v-if="ind % 2 == 0" style="background-color: #fff !important;">
                                    {{ss[1]}}
                                </td>
                                <td width="50%" v-else style="background-color: #f7f7f7 !important;">
                                    {{ss[1]}}
                                </td>
                            </tr>
                        </table>
                    </section>

                </section>
            </vue-html2pdf>
    </div>
</template>

<script>
    import {
        BCardHeader, BCardTitle, BCardText, BCardBody, BFormSelect, BFormSelectOption, BCard, BRow, 
        BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, 
        BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm
    } from 'bootstrap-vue'
    import { POST_API } from "../../../store/actions.type"
    import vSelect from 'vue-select';
    import VueApexCharts from 'vue-apexcharts'
    import flatPickr from 'vue-flatpickr-component'
    import moment from "moment-timezone";
    import { $themeColors } from '@themeConfig'
    import ChartjsComponentBarChart from '../charts-components/ChartjsComponentBarChart.vue'
    import VueHtml2pdf from 'vue-html2pdf'
    import Bus from "../../../event-bus";

    export default {
        props: ['device_type', 'device_name', 'color', 'date_range', 'temperatureOptions', 'temperatureSeries', 'humidityOptions', 'humiditySeries'],
        
        components: {
            VueHtml2pdf, ChartjsComponentBarChart, flatPickr, VueApexCharts, BCard, BCardHeader, BCardTitle, BCardText, 
            BCardBody, vSelect, BFormSelect, BFormSelectOption, BRow, BCol, BFormInput, BButton, BTable, 
            BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, 
            BAlert, BFormText, BFormGroup, BForm
        },

        data() {
            return {
                organization_logo : '',
                site_logo : '',

                error_message: null
            }
        },

        methods : {
            onProgress(event) {
                if(event == 100){
                    Swal.fire({
                        position : 'center',
                        icon     : 'success',
                        title    : 'PDF Downloaded.',
                        timer    : 1500,
                        showConfirmButton : false
                    });
                    Bus.$emit('hideLoader');
                }
            },
            generatePDF() {
                Bus.$emit('showLoader');
                this.$refs.html2Pdf.generatePdf();
            },

            beforeDownload ({ html2pdf, options, pdfContent }) {
                options.margin = .5;
                options.useCORS = true;

                html2pdf().set(options);/* .from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i)
                        pdf.setFontSize(10)
                        pdf.setTextColor(150)
                        pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    } 
                }).save() */
            },

            getheaderImages(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        id : this.$route.params.id ? this.$route.params.id : this.$route.params.device_id,
                    },
                    api: '/api/get-company-logo'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } 
                    else {
                        this.organization_logo = this.$store.getters.getResults.data.organization_logo;
                        this.site_logo = this.$store.getters.getResults.data.site_logo;
                    }
                });
            },
        },

        mounted(){
            this.getheaderImages();
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
    table{
        padding: 0px !important;   
        margin: 0px !important;   
    }
    th {
        background-color: #D4AF37 !important; 
    }
    td, th {
        border-left: 1px solid #000000 !important;
        border-right: 1px solid #000000 !important;
        border-bottom: 1px solid #000000 !important;
        padding: 2px !important;
        color: #000 !important; 
    }
    td {
        /* background-color: #fff !important;  */
        border-top: 0px !important;
    }
    th {
        border-top: 1px solid #000000 !important;
    }
    @media print {
        .image-block{
            page-break-after: always;
        }
    }

</style>